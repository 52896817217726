<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-body>
                <mdb-row>
                    <mdb-col md="9">
                        <mdb-input label="商品名稱" v-model="prod.name"></mdb-input>
                    </mdb-col>
                    <mdb-col md="3">
                        <mdb-switch
                            class="mt-4"
                            color="primary"
                            offLabel=""
                            :onLabel="prod.active ? '啟用' : '停用'"
                            :checked="prod.active"
                            @getValue="(val) => (prod.active = val)"
                        />
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col md="6" v-if="prod.main_pic">
                        <img :src="'/public/source/' + prod.main_pic" style="width: 100%;" />
                    </mdb-col>
                    <mdb-col md="6">
                        <imagepicker v-model="prod.main_pic"></imagepicker>
                    </mdb-col>
                    <mdb-col md="6">
                        <mdb-select
                            label="分類"
                            search
                            searchPlaceholder="搜尋分類"
                            placeholder="請選取分類"
                            @getValue="get_product_class"
                            :options="product_classList"
                        />
                    </mdb-col>
                    <mdb-col md="6">
                        <mdb-input label="排序" type="number" v-model="prod.sort"></mdb-input>
                    </mdb-col>
                    <mdb-col md="12">
                        <mdb-input type="textarea" label="簡介(放在商品檢視頁面圖片的右邊)" v-model="prod.s_info" :rows="5" />
                    </mdb-col>
                    <mdb-col md="12">
                        <tinycme-editor v-model="prod.info" :base_url="$store.state.backend_url"></tinycme-editor>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <div class="text-right mb-4">
            <mdb-btn color="default" @click="savedata()">
                <i class="fa fa-save"></i>
                &nbsp;儲存
            </mdb-btn>
        </div>
    </div>
</template>
<script>
    import { mdbCard, mdbCardBody, mdbRow, mdbCol, mdbInput, mdbSelect, mdbBtn, mdbSwitch } from 'mdbvue';
    import imagepicker from '../../components/imagepicker';
    import tinycmeEditor from '../../components/tinymce';

    export default {
        components: {
            mdbCard,
            mdbCardBody,
            mdbInput,
            mdbRow,
            mdbSelect,
            mdbCol,
            mdbBtn,
            mdbSwitch,
            imagepicker,
            tinycmeEditor,
        },
        props: {
            id_product: {
                default: 0,
            },
        },
        data() {
            return {
                prod: {
                    class: [],
                },
                data: {},
                supplier: [],
                product_classList: [],
                repair_targetList: [],
            };
        },
        watch: {
            '$route.query.id_product'() {
                this.get_data();
            },
        },
        mounted() {
            this.get_data();
        },
        methods: {
            get_data() {
                let vue = this,
                    query = [
                        {
                            name: 'product',
                            data: {
                                id: {
                                    type: 0,
                                    value: this.id_product,
                                },
                            },
                        },
                        {
                            name: 'product_class',
                            data: {},
                            sort: {
                                'if(`id_parent`=0,`id`,`id_parent`)': 0,
                                sort: 1,
                                id_parent: 0,
                            },
                        },
                        {
                            name: 'product',
                        },
                    ];
                vue.$store
                    .dispatch('get_form', {
                        payload: {
                            url: `data/get_sql_data/?query=${JSON.stringify(query)}`,
                        },
                    })
                    .then((res) => {
                        if (this.id_product > 0) {
                            res.data.productList[0].active = res.data.productList[0].active == '1';
                            vue.prod = res.data.productList[0];
                        } else {
                            res.data.productEmpty.active = true;
                            res.data.productEmpty.class = '';
                            res.data.productEmpty.name = '';
                            res.data.productEmpty.id_product_class = '';
                            vue.prod = res.data.productEmpty;
                        }
                        vue.product_classList = res.data.product_classList.map((item) => {
                            item.value = item.id;
                            item.text = item.name;
                            item.optgroup = item.id_parent == 0;
                            item.selected = vue.prod.id_product_class == item.id;
                            return item;
                        });
                    });
            },
            get_product_class() {
                let vue = this;
                vue.product_classList.findIndex((item) => {
                    if (item.selected) {
                        vue.prod.id_product_class = item.id;
                        return true;
                    }
                    return false;
                });
            },
            // 儲存資料
            savedata() {
                let vue = this,
                    msg = '';
                switch (true) {
                    case !vue.prod.name:
                    case vue.prod.name.trim() == '':
                        msg = '請填寫 商品名稱';
                        break;
                }
                if (msg == '') {
                    vue.$store
                        .dispatch('post_form', {
                            payload: {
                                url: 'product/save_process',
                                data: {
                                    data: JSON.stringify(vue.prod),
                                },
                            },
                        })
                        .then((res) => {
                            if (res.data.status == 'ok') {
                                vue.$swal
                                    .fire({
                                        icon: 'success',
                                        title: '儲存完成!',
                                        timer: 1500,
                                        showConfirmButton: false,
                                    })
                                    .then(() => {
                                        // console.info(res.data);
                                        if (vue.prod.id != res.data.id_product) {
                                            vue.$router.push('/product/edit/?id_product=' + res.data.id_product);
                                        }
                                    });
                            }
                        });
                } else {
                    // console.info(vue.prod);
                    vue.$swal.fire({
                        icon: 'error',
                        title: msg,
                        timer: 1500,
                        showConfirmButton: false,
                        toast: true,
                    });
                }
            },
        },
    };
</script>
