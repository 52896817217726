<template>
    <div class="editor">
        <editor v-model="editorValue" :init="init"></editor>
    </div>
</template>
<script>
    import $store from '../store/store';
    import tinymce from 'tinymce/tinymce.js';
    // import 'tinymce/models/dom'; (TinyMCE 6)

    // 外觀
    import 'tinymce/skins/ui/oxide/skin.css';
    import 'tinymce/themes/silver';

    // Icon
    import 'tinymce/icons/default';

    // 用到的外掛
    import 'tinymce/plugins/emoticons';
    import 'tinymce/plugins/emoticons/js/emojis.js';
    import 'tinymce/plugins/table';
    import 'tinymce/plugins/quickbars';
    import 'tinymce/plugins/media';
    import 'tinymce/plugins/lists';
    import 'tinymce/plugins/link';
    import 'tinymce/plugins/image';
    import 'tinymce/plugins/imagetools';
    import 'tinymce/plugins/fullscreen';
    // 媒體庫組件
    let T = true;
    if (T) {
        // require('./responsivefilemanager/plugin.min.js');
        /**
         * plugin.js
         *
         * Copyright, Alberto Peripolli
         * Released under Creative Commons Attribution-NonCommercial 3.0 Unported License.
         *
         * Contributing: https://github.com/trippo/ResponsiveFilemanager
         */

        tinymce.PluginManager.add('responsivefilemanager', function(editor) {
            function responsivefilemanager_onMessage(event) {
                if (editor.settings.external_filemanager_path.toLowerCase().indexOf(event.origin.toLowerCase()) === 0) {
                    if (event.data.sender === 'responsivefilemanager') {
                        tinymce.activeEditor.insertContent(event.data.html);
                        tinymce.activeEditor.windowManager.close();

                        // Remove event listener for a message from ResponsiveFilemanager
                        if (window.removeEventListener) {
                            window.removeEventListener('message', responsivefilemanager_onMessage, false);
                        } else {
                            window.detachEvent('onmessage', responsivefilemanager_onMessage);
                        }
                    }
                }
            }

            function openmanager() {
                var width = window.innerWidth - 20;
                var height = window.innerHeight - 40;
                if (width > 1800) width = 1800;
                if (height > 1200) height = 1200;
                if (width > 600) {
                    var width_reduce = (width - 20) % 138;
                    width = width - width_reduce + 10;
                }

                editor.focus(true);
                var title = 'RESPONSIVE FileManager';
                if (typeof editor.settings.filemanager_title !== 'undefined' && editor.settings.filemanager_title) {
                    title = editor.settings.filemanager_title;
                }
                var akey = 'key';
                if (typeof editor.settings.filemanager_access_key !== 'undefined' && editor.settings.filemanager_access_key) {
                    akey = editor.settings.filemanager_access_key;
                }
                var sort_by = '';
                if (typeof editor.settings.filemanager_sort_by !== 'undefined' && editor.settings.filemanager_sort_by) {
                    sort_by = '&sort_by=' + editor.settings.filemanager_sort_by;
                }
                var descending = 'false';
                if (typeof editor.settings.filemanager_descending !== 'undefined' && editor.settings.filemanager_descending) {
                    descending = editor.settings.filemanager_descending;
                }
                var fldr = '';
                if (typeof editor.settings.filemanager_subfolder !== 'undefined' && editor.settings.filemanager_subfolder) {
                    fldr = '&fldr=' + editor.settings.filemanager_subfolder;
                }
                var crossdomain = '';
                if (typeof editor.settings.filemanager_crossdomain !== 'undefined' && editor.settings.filemanager_crossdomain) {
                    crossdomain = '&crossdomain=1';

                    // Add handler for a message from ResponsiveFilemanager
                    if (window.addEventListener) {
                        window.addEventListener('message', responsivefilemanager_onMessage, false);
                    } else {
                        window.attachEvent('onmessage', responsivefilemanager_onMessage);
                    }
                }

                const fileUrl =
                    editor.settings.external_filemanager_path +
                    'dialog.php?type=4&descending=' +
                    descending +
                    sort_by +
                    fldr +
                    crossdomain +
                    '&lang=' +
                    editor.settings.language +
                    '&akey=' +
                    akey;

                if (tinymce.majorVersion < 5) {
                    editor.windowManager.open({
                        title: title,
                        file: fileUrl,
                        width: width,
                        height: height,
                        inline: 1,
                        resizable: true,
                        maximizable: true,
                    });
                } else {
                    editor.windowManager.openUrl({
                        title: title,
                        url: fileUrl,
                        width: width,
                        height: height,
                        inline: 1,
                        resizable: true,
                        maximizable: true,
                    });
                }
            }

            if (tinymce.majorVersion < 5) {
                editor.addButton('responsivefilemanager', {
                    icon: 'browse',
                    tooltip: 'Insert file',
                    shortcut: 'Ctrl+E',
                    onClick: openmanager,
                });

                editor.addShortcut('Ctrl+E', '', openmanager);

                editor.addMenuItem('responsivefilemanager', {
                    icon: 'browse',
                    text: 'Insert file',
                    shortcut: 'Ctrl+E',
                    onClick: openmanager,
                    context: 'insert',
                });
            } else {
                editor.ui.registry.addButton('responsivefilemanager', {
                    icon: 'browse',
                    tooltip: 'Insert file',
                    shortcut: 'Ctrl+E',
                    onAction: openmanager,
                });

                editor.addShortcut('Ctrl+E', '', openmanager);

                editor.ui.registry.addMenuItem('responsivefilemanager', {
                    icon: 'browse',
                    text: 'Insert file',
                    shortcut: 'Ctrl+E',
                    onAction: openmanager,
                    context: 'insert',
                });
            }
        });
    }
    // 語言包
    import 'tinymce-i18n/langs5/zh_TW.js';
    // import 'tinymce-i18n/langs/zh_Hans.js' (TinyMCE 6 的簡體中文)

    // TinyMCE-Vue
    import Editor from '@tinymce/tinymce-vue';

    export default {
        components: {
            Editor,
        },
        props: {
            base_url: { default: '' },
            ADMIN_ID: { default: '' },
            ADMIN_token: { default: '' },
            value: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                init: {
                    language: 'zh_TW',
                    height: 500,
                    menubar: false,
                    content_css: false,
                    skin: false,
                    plugins: 'quickbars emoticons table link image imagetools media lists responsivefilemanager fullscreen ',
                    toolbar: [
                        'undo redo | removeformat | link responsivefilemanager media table | fullscreen',
                        'bold italic underline strikethrough | fontselect fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | numlist bullist  | outdent indent | emoticons',
                    ],
                    contextmenu: 'link image imagetools table configurepermanentpen',
                    quickbars_insert_toolbar: false,
                    branding: false,
                    external_filemanager_path: '/filemanager/',
                    filemanager_title: '媒體庫',
                    image_advtab: true,
                    filemanager_access_key: '&ADMIN_ID=' + $store.state.user.id + '&token=' + $store.state.user.login_token,
                },
                editorValue: this.value,
            };
        },
        mounted() {
            tinymce.init({});
        },
        watch: {
            value(newValue) {
                this.editorValue = newValue;
            },
            editorValue(newValue) {
                this.$emit('input', newValue);
            },
        },
        methods: {},
    };
</script>
